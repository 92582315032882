import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { config } from '@abyss/web/tools/config';
import { getFeature } from '@hhs/api/src/tools/features';
import { styles } from './PrivacyPolicyCmn.styles';
import { Header } from '../../Header';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';

export const PrivacyPolicyCmn = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const location = router.getLocation(); 
  const [hourOfOperationText, setHourOfOperationText] = useState(''); 

  const handleGoBack = () => {
    const url = window.location.pathname.split('/');
    if ((url[1] === 'attestation' || url[1] === 'registration' || url[1] === 'docusign') &&
      (url[2] === 'contact-us' || url[2] === 'accessibility-statement' || url[2] === 'terms-of-use' || url[2] === 'privacy-policy')) {
      router.navigate('/');
      return;
    }
    if (window.location.pathname.split('/').length > 2) {  
      router.navigate(-1);  
    } else {  
      //router.navigate(-1);  
      router.navigate('/');
    }    
  };

  useEffect(() => {
    const urlToSetHours = window.location.pathname.split('/');
    var hooText = getFeature('HRSA_PRF_HOO');
    const hooDisplayText = `You can contact us regarding this Website Privacy Policy and our
    related privacy practices at 866-569-3522 for TTY dial 711. ` +  hooText;
    setHourOfOperationText(hooDisplayText);
  }, []);  

  return (
    <React.Fragment>
      <PageLayout>
        <div className={classes.sectionHeading}>
          <h2>Website Privacy Policy</h2>
        </div>
        <div className={classes.section}>
          <h2>Introduction</h2>
        </div>
        <div className={classes.section}>
          We recognize that the privacy of your personal information is
          important. The purpose of this policy is to let you know how we handle
          the information collected through the use of this website. Portions of
          this website may describe privacy practices applicable to specific
          types of information or to information provided on specific web pages.
        </div>
        <div className={classes.section}>
          This policy does not apply to information collected through other
          means such as by telephone or in person, although that information may
          be protected by other privacy policies. As used in this policy, terms
          such as “we” or “our” and “Company” refer to UnitedHealth Group and
          its current and future affiliated entities.
        </div>
        <div className={classes.section}>
          This website is intended for a United States audience. Any information
          you provide, including any personal information, will be transferred
          to and processed by a computer server located within the United
          States.
        </div>
        <div className={classes.section}>
          <h2>Your Personal Information</h2>
        </div>
        <div className={classes.section}>
          This website may include web pages that give you the opportunity to
          provide us with personal information about yourself. You do not have
          to provide us with personal information if you do not want to;
          however, that may limit your ability to use certain functions of this
          website or to request certain services or information.
        </div>
        <div className={classes.section}>
          Certain personal information you provide on this website will be
          subject to the Privacy Act of 1974 and a Privacy Act Statement will be
          provided before that collection. The statement will describe the
          purpose for which that personal information will be used.
        </div>
        <div className={classes.section}>
          We may use personal information for a number of purposes such as:
        </div>
        <div className={classes.section}>
          <ul className={classes.list}>
            <li>To respond to an email or particular request from you.</li>
            <li>To personalize the website for you.</li>
            <li>To process an application as requested by you.</li>

            <li>To administer surveys.</li>
            <li>
              To provide you with information that we believe may be useful to
              you.
            </li>
            <li>
              To perform analytics and to improve our products, websites, and
              advertising.
            </li>
            <li>
              To comply with applicable laws, regulations, and legal process.
            </li>
            <li>To protect someone's health, safety, or welfare.</li>
            <li>
              To protect our rights, the rights of affiliates or related third
              parties, or take appropriate legal action, such as to enforce our
              Terms of Use.
            </li>
            <li>To keep a record of our transactions and communications.</li>
            <li>
              As otherwise necessary or useful for us to conduct our business,
              so long as such use is permitted by law.
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          We may use personal information to contact you through any contact
          information you provide through this website, including any email
          address, telephone number, cell phone number, text message number, or
          fax number. Please see the section below titled “Our Online
          Communications Practices.”
        </div>
        <div className={classes.section}>
          We may also share personal information within the Company, and we may
          combine personal information that you provide us through this website
          with other information we have received from you, whether online or
          offline, or from other sources. For example, we may combine personal
          information you provided to the U.S. Department of Health and Human
          Services with information you provide in an attestation on this site.
        </div>
        <div className={classes.section}>
          <h2>Sharing Personal Information</h2>
        </div>
        <div className={classes.section}>
          We will only share your personal information with third parties as
          outlined in this policy and as otherwise permitted by law, including
          information provided in the attestation process and payment
          information with the U.S. Department of Health and Human Services.
        </div>
        <div className={classes.section}>
          Certain personal information you provide on this website will be
          subject to the Privacy Act of 1974 and a Privacy Act Statement will be
          provided before that collection that provides more information about
          the sharing of that personal information. We may share personal
          information if all or part of the Company is sold, merged, dissolved,
          acquired, or in a similar transaction. We may share personal
          information in response to a court order, subpoena, search warrant,
          law or regulation. We may cooperate with law enforcement authorities
          in investigating and prosecuting activities that are illegal, violate
          our rules, or may be harmful to other visitors. We may also share
          personal information with other third party companies that we
          collaborate with or hire to perform services on our behalf. For
          example, we may hire a company to help us send and manage email, and
          we might provide the company with your email address and certain other
          information in order for them to send you an email message on our
          behalf. Similarly, we may hire companies to host or operate some of
          our websites and related computers and software applications. This
          website may permit you to view your visitor profile and related
          personal information and to request changes to such information. If
          this function is available, we will include a link on this website
          with a heading such as “My Profile” or similar words. Clicking on the
          link will take you to a page through which you may review your visitor
          profile and related personal information.
        </div>
        <div className={classes.section}>
          <h2>Website and Information Security</h2>
        </div>
        <div className={classes.section}>
          We maintain reasonable administrative, technical and physical
          safeguards designed to protect the information that you provide on
          this website. However, no security system is impenetrable and we
          cannot guarantee the security of our website, nor can we guarantee
          that the information you supply will not be intercepted while being
          transmitted to us over the Internet, and we are not liable for the
          illegal acts of third parties such as criminal hackers.
        </div>
        <div className={classes.section}>
          <h2>Our Online Communication Practices</h2>
        </div>
        <div className={classes.section}>
          We may send email communications regarding the CARES Act Provider
          Relief Fund and/or COVID-19 HRSA Uninsured Testing and Treatment Fund
          (collectively “Relief Fund”). These communications may include, but
          are not limited to, information about either program, payment status
          in connection with either program, and/or eligibility to receive funds
          under either program.
        </div>
        <div className={classes.section}>
          By providing your email and phone number, you agree that HHS/HRSA or
          its contractor may send you communications or call you regarding
          Relief Fund Payment. As part of your enrollment in Optum ID and/or
          Optum Pay, you may also receive communications that are related to the
          Optum ID and/or Optum Pay services and unrelated to Relief Fund
          Payment. During your participation in the Relief Fund Payment program
          you will continue to receive such communications. After the conclusion
          of your participation in the Relief Fund Payment program, if you want
          to discontinue receiving communications associated with the Optum ID
          service, please follow the instructions associated with managing your
          Optum ID account available at{' '}
          <a
            href="https://www.uhcprovider.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.uhcprovider.com
          </a>
          . If you want to discontinue receiving communications associated with
          the Optum Pay service, please log in and follow the instructions
          associated with managing your Optum Pay account available at{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://myservices.optumhealthpaymentservices.com/registrationSignIn.do"
          >
            https://myservices.optumhealthpaymentservices.com/registrationSignIn.do
          </a>
        </div>
        <div className={classes.section}>
          <h2>Cookies and Tracking</h2>
        </div>
        <div className={classes.section}>
          The Company may use various technologies, including cookies, tokens,
          tags, web logs, web beacons, scripts, and web server logs to gather
          automatically-collected information and may aggregate this information
          from our Online Services visitors or to enable certain features of our
          Online Services. This information may include demographic data,
          technical information about the technology (e.g., phone, computer)
          used to connect to the Online Services, web browser information, your
          IP address, and browsing behavior such as pages visited and how often
          they are visited ("Activity Information"). We may also use third party
          analytics companies to provide these services.
        </div>
        <div className={classes.section}>
          We may also allow third party service providers to use cookies and
          other technologies to collect information and to track browsing
          activity over time and across third party websites such as web
          browsers used to read our websites, which websites are referring
          traffic or linking to our websites, and to deliver targeted
          advertisements to you. We do not control these third party
          technologies and their use is governed by the privacy policies of
          third parties using such technologies. For more information about
          third party advertising networks and similar entities that use these
          technologies, see{' '}
          <a
            href={config('ABOUT_ADS_CONSUMERS')}
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.aboutads.info/consumers
          </a>
          , and to opt-out of such ad networks’ and services’ advertising
          practices, go to{' '}
          <a
            href={config('ABOUT_ADS_CHOICES')}
            target="_blank"
            rel="noopener noreferrer"
          >
            www.aboutads.info/choices
          </a>{' '}
          and{' '}
          <a
            href={config('NETWORK_ADVERTISING_CHOICES')}
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.networkadvertising.org/choices
          </a>
          . Once you click the link, you may choose to opt-out of such
          advertising from all participating advertising companies or only
          advertising provided by specific advertising companies. Please note
          that to the extent advertising technology is integrated into the
          Online Services, you may still receive advertisements even if you
          opt-out of tailored advertising. In that case, the ads will just not
          be tailored. Also, we do not control any of the above opt-out links
          and are not responsible for any choices you make using these
          mechanisms or the continued availability or accuracy of these
          mechanisms.
        </div>
        <div className={classes.section}>
          Activity Information is captured using various technologies and may
          include cookies. "Cookies" are small text files that may be placed on
          your computer when you visit an Online Service or click on a URL.
          Cookies may include "single-session cookies" which generally record
          information during only a single visit to a website and then are
          erased, and "persistent" cookies which are generally stored on a
          computer unless or until they are deleted or are set to expire. You
          may disable cookies and similar items by adjusting your browser
          preferences at any time; however, this may limit your ability to take
          advantage of all the features on our Online Services. In addition, you
          may also have additional means to manage the collection of Activity
          Information by:
        </div>
        <div className={classes.section}>
          <ul className={classes.list}>
            <li>
              Managing the use of "flash" technologies with the Flash management
              tools available at Adobe’s website;
            </li>
            <li>
              Visiting the Adobe Digital Marketing Suite to "Opt-Out" of of data
              aggregation and analysis;
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          Please note that we do not currently respond to web browser "Do Not
          Track" signals that provide a method to opt-out of the collection of
          information about online activities over time and across third party
          websites or online services because, among other reasons, there is no
          common definition of such signals and no industry-accepted standards
          for how such signals should be interpreted.
        </div>
        <div className={classes.section}>
          We gather Activity Information about you in order to improve the
          quality of our services, such as the best method and time to contact
          you. Without limiting the other ways in which we may use Information
          as described herein, we may otherwise use and disclose your Activity
          Information unless restricted by this Policy or by law. Some examples
          of the ways we may use your Activity Information include:
        </div>
        <div className={classes.section}>
          <ul className={classes.list}>
            <li>
              Customizing your experiences, including managing and recording
              your preferences;
            </li>
            <li>Authenticating your account information;</li>
            <li>Marketing, product development, and research purposes;</li>
            <li>
              Tracking resources and data accessed on the Online Services;
            </li>
            <li>
              Developing reports regarding Online Service usage, activity, and
              statistics;
            </li>
            <li>Assisting users experiencing problems with our services;</li>
            <li>Updating and servicing our Online Services;</li>
            <li>
              Enabling certain functions and tools on the Online Services; and
            </li>
            <li>
              Tracking paths of visitors to the Online Services and within the
              Online Services.
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          As described above, we may use tracking technologies that allow us
          recognize your device when you return to our Online Services within a
          period of time, as determined by us, and to support automatic login to
          your Online Services. To maintain your privacy, you should
          affirmatively log out of your account prior to your session ending
          (whether you end your session or we end your session, for example if
          our Online Services has "timed out" – i.e. we have ended your session
          automatically after a period of inactivity as determined by us in our
          sole discretion). Unless you affirmatively log out of your account,
          you may be automatically logged back in the next time you, or any user
          of your devices visits the Online Services.
        </div>
        <div className={classes.section}>
          <h2>Information for Children Under 13</h2>
        </div>
        <div className={classes.section}>
          We will not intentionally collect any personal information from
          children under the age of 13 through this website without receiving
          parental consent. If you think that we have collected personal
          information from a child under the age of 13 through this website,
          please contact us.
        </div>
        <div className={classes.section}>
          <h2>Contact Us</h2>
        </div>
        <div className={classes.section}>
          {hourOfOperationText}
        </div>
        <div className={classes.section}>
          <h2>Effective Date</h2>
        </div>
        <div className={classes.section}>
          The effective date of this policy is April 10, 2020.
        </div>
        <div className={classes.section}>
          <h2>Changes to this Website Privacy Policy</h2>
        </div>
        <div className={classes.section}>
          We may change this policy. If we do so, such change will appear on
          this page of our website. We will also provide appropriate notice and
          choices to you, on this website and in other appropriate locations,
          based on the scope and extent of changes. You may always visit this
          policy to learn of any updates.
        </div>
        <div className={classes.section}>
          <h2>Social Security Number Protection Policy</h2>
        </div>
        <div className={classes.section}>
          Protecting personal information is important to us. It is our policy
          to protect the confidentiality of Social Security numbers ("SSNs”)
          that we receive or collect in the course of business. We secure the
          confidentiality of SSNs through various means, including physical,
          technical, and administrative safeguards that are designed to protect
          against unauthorized access. It is our policy to limit access to SSNs
          to that which is lawful, and to prohibit unlawful disclosure of SSNs.
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
